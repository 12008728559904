<template>
	<v-layout class="main-layout" wrap :style="`background-color:${$store.state.MainColor1};`">
		<routerIcons />
		<v-flex xs12>
			<div class="text-right mb-2 mr-4">
				<v-btn @click="create" color="success" v-if="$store.state.myData.cls === '1'">
					create
				</v-btn>
			</div>
			<v-layout align-center class="mb-2 mx-auto">
				<div>
					<v-btn :color="projectType ? '' : 'PointColor1'" :dark="!projectType" @click="projectList()">
						my Project
					</v-btn>
					<v-btn
						:class="$vuetify.breakpoint.xs ? 'mt-2' : 'ml-2'"
						:color="projectType ? 'PointColor1' : ''"
						:dark="projectType"
						@click="projectList()"
					>
						Project List
					</v-btn>
				</div>
				<v-spacer></v-spacer>
				<textField
					v-model="search"
					:textField="SearchTextField"
					class="mr-4"
					@click:prepend-inner="searchProject(search)"
					@keydown.enter="searchProject(search)"
				/>
			</v-layout>
		</v-flex>
		<v-flex xl3 lg4 md6 sm12 xs12 v-for="(project, i) in calendarsList" :key="i" class="mb-4">
			<v-card
				:class="$vuetify.breakpoint.xs ? 'cursor' : 'mr-4 cursor'"
				color="PointColor2"
				dark
				height="240"
				@click="projectDetailOpen(project)"
			>
				<v-card-title>
					<h3 class="text-overflow">
						{{ calendarsList.length - i + 1 }}. <span class="text-h6 font-weight-light"> {{ project.name }}</span>
					</h3>
				</v-card-title>

				<v-card-text class="text-h5 font-weight-bold project-content">
					{{ project.content }}
				</v-card-text>

				<v-card-actions>
					<v-list-item class="grow">
						<v-list-item-avatar color="grey darken-3">
							<v-img
								class="elevation-6"
								alt=""
								v-if="
									project.team !== null &&
										project.pmName !== null &&
										project.team.users_permissions_users.filter(x => x.Name === project.pmName)[0]
								"
								:src="server + project.team.users_permissions_users.filter(x => x.Name === project.pmName)[0].face.url"
							></v-img>
							<v-img
								class="elevation-6"
								alt=""
								v-else
								src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
							></v-img>
						</v-list-item-avatar>

						<v-list-item-content>
							<v-list-item-title class="PointColor3--text"> pm: {{ project.pmName }}</v-list-item-title>
							<v-list-item-title>{{ project.start }} ~ {{ project.end }}</v-list-item-title>
						</v-list-item-content>
						<v-flex xs1>
							<v-row align="center" justify="end">
								<v-icon class="mr-1">
									mdi-account-group
								</v-icon>
								<span class="subheading">{{ project.team ? project.team.users_permissions_users.length : 0 }}</span>
							</v-row>
						</v-flex>
					</v-list-item>
				</v-card-actions>
			</v-card>
		</v-flex>
		<v-flex xs12 v-show="calendarsList.length === 0">
			<v-layout align-center justify-center style="min-height:500px;">
				<div class="text-center white--text">No-data</div>
			</v-layout>
		</v-flex>
		<dialog1 :setDialog="setDialog1" @cancel="setDialog1.dialog = false" @ok="deleteProject" />
		<createProject :data="project" @create="createCalendar" @selectBox1="teamSelect" />
		<detailProject
			:data="projectDetail"
			@deleteProject="deleteDialog(projectDetail.project.id, projectDetail.project.name)"
			@editProject="editDialog(projectDetail.project.id, projectDetail)"
		/>
	</v-layout>
</template>
<script>
import { routerIcons } from './index'
import { textField, dialog1, createProject, detailProject } from '@/components'
export default {
	components: { textField, routerIcons, dialog1, createProject, detailProject },
	data() {
		return {
			projectDetail: {
				dialog: false,
				project: {
					name: '',
					teams: [],
				},
				team: {
					title: '',
				},
			},
			project: {
				dialog: false,
				edit: false,
				picker1: {
					dialog: false,
					date: new Date().toISOString().substr(0, 10),
					label: 'start',
				},
				picker2: {
					dialog: false,
					date: new Date().toISOString().substr(0, 10),
					label: 'end',
				},
				selectBox1: {
					width: '100%',
					clearable: true,
					reverse: false,
					color: '#5680e9ff',
					rounded: true,
					label: '담당 팀',
					items: [],
					outlined: true,
					['hide-details']: false,
				},
				selectBox2: {
					width: '100%',
					clearable: true,
					reverse: false,
					color: '#5680e9ff',
					rounded: true,
					label: '담당PM',
					items: [],
					outlined: true,
					['hide-details']: false,
				},
				team: '',
			},
			projectType: false,
			setDialog1: {
				dialog: false,
				title: 'PASSWORD RESET',
				content: 'The password is changed to "TEST1234"',
				btnText: 'PASSWORD RESET',
			},
			search: '',
			SearchTextField: {
				dark: true,
				width: '200',
				placeholder: 'Search',
				['prepend-inner-icon']: 'mdi-magnify',
				solo: true,
				type: 'text',
				maxlength: 255,
			},
			server: process.env.VUE_APP_BACKEND_URL,
			calendarsList: [],
			teamsList: [],
			teamId: null,
			projectId: null,
		}
	},
	methods: {
		deleteProject() {
			const data = {
				status: 99,
				id: this.projectId,
			}
			this.$store
				.dispatch('updateCalendar', data)
				.then(() => {
					this.calendars()
					this.projectDetail.dialog = false
					this.setDialog1.dialog = false
				})
				.catch(err => {
					console.log({ err })
				})
		},
		editDialog(projectId, project) {
			this.projectId = projectId
			this.project.dialog = true
			this.project.edit = true
			this.project.name = project.project.name
			this.project.client = project.project.client
			this.project.content = project.project.content
			this.project.pmName = project.project.pmName
			this.project.picker1.date = project.project.start
			this.project.picker2.date = project.project.end
			if (project.project.team) {
				this.project.team = project.project.team.title
				this.teams(project.project.team.title)
			} else {
				this.teams()
			}
		},
		deleteDialog(projectId, projectName) {
			this.projectId = projectId
			this.setDialog1.dialog = true
			this.setDialog1.title = 'Delete Project'
			this.setDialog1.content = 'Are you really sure to delete ' + projectName + ' to the Project?'
			this.setDialog1.btnText = 'Delete'
		},
		projectDetailOpen(project) {
			this.projectDetail.project = project
			if (project.team) {
				this.projectDetail.team = {
					id: project.team.id,
					tab: 0,
					tabs: ['Team List', 'join team'],
					title: project.team.title,
					list: project.team.users_permissions_users,
					teamPosition: project.team.teamPosition,
				}
			} else {
				this.projectDetail.team = {}
			}
			this.projectDetail.dialog = true
		},
		teamSelect(val) {
			if (val !== null) {
				this.project.selectBox2.items = this.teamsList.filter(x => x.title === val)[0].users_permissions_users.map(y => y.Name)
				this.teamId = this.teamsList.filter(x => x.title === val)[0].id
			}
		},
		teams(edit) {
			this.$store
				.dispatch('teams')
				.then(res => {
					this.teamsList = res.teams
					this.project.selectBox1.items = res.teams.map(x => x.title)
					if (edit) {
						this.teamSelect(edit)
					}
				})
				.catch(err => {
					console.log({ err })
				})
		},
		searchProject(search) {
			this.calendars(search)
		},
		projectList() {
			this.projectType = !this.projectType
			if (this.projectType) {
				this.calendars()
			} else {
				const setArray = []
				this.calendarsList
					.filter(x => x.team !== null)
					.forEach(element => {
						if (element.team) {
							element.team.users_permissions_users.forEach(user => {
								if (user.email.indexOf(this.$store.state.myData.email) > -1) {
									setArray.push(element)
								}
							})
						}
					})
				this.calendarsList = setArray
			}
		},
		create() {
			this.project.dialog = true
			this.project.edit = false
			this.project.name = ''
			this.project.client = ''
			this.project.content = ''
			this.project.pmName = ''
			this.project.picker1.date = new Date().toISOString().substr(0, 10)
			this.project.picker2.date = new Date().toISOString().substr(0, 10)
			this.teams()
		},
		updateCalendar() {
			if (this.project.team) {
				this.teamId = this.teamsList.filter(x => x.title === this.project.team)[0].id
			} else {
				this.teamId = null
			}
			const data = {
				name: this.project.name,
				client: this.project.client,
				pmName: this.project.pmName,
				start: this.project.picker1.date,
				end: this.project.picker2.date,
				content: this.project.content,
				team: this.teamId,
				id: this.projectId,
			}
			this.$store
				.dispatch('updateCalendar', data)
				.then(() => {
					this.calendars()
					this.projectDetail.dialog = false
				})
				.catch(err => {
					console.log({ err })
				})
		},
		createCalendar() {
			if (this.project.edit) {
				this.updateCalendar()
			} else {
				const data = {
					name: this.project.name,
					client: this.project.client,
					pmName: this.project.pmName,
					start: this.project.picker1.date,
					end: this.project.picker2.date,
					content: this.project.content,
					chat: [],
					team: this.teamId,
				}
				this.$store
					.dispatch('createCalendar', data)
					.then(res => {
						this.calendars()
						this.createTodolist(res.createCalendar.calendar.id, res.createCalendar.calendar.interID)
					})
					.catch(err => {
						alert('중복된 프로젝트 제목이 존재합니다.')
						console.log({ err })
					})
			}
		},
		createTodolist(id, interID) {
			const data = {
				interID: interID,
				list: [],
				calendar: id,
				createDay: this.$moment(new Date()).format('YYYY-MM-DD'),
			}
			this.$store
				.dispatch('createTodolist', data)
				.then(() => {})
				.catch(err => {
					console.log({ err })
				})
		},
		calendars(search) {
			let data
			if (search) {
				data = {
					name: search,
				}
			}
			this.$store
				.dispatch('calendars', data)
				.then(res => {
					console.log(res)
					this.calendarsList = res.calendars
					this.project.dialog = false
					if (!this.projectType) {
						const setArray = []
						this.calendarsList
							.filter(x => x.team !== null)
							.forEach(element => {
								if (element.team) {
									element.team.users_permissions_users.forEach(user => {
										if (user.email.indexOf(this.$store.state.myData.email) > -1) {
											setArray.push(element)
										}
									})
								}
							})
						this.calendarsList = setArray
					}
				})
				.catch(err => {
					console.log({ err })
				})
		},
	},
	created() {
		this.calendars()
	},
}
</script>
<style lang="scss">
.project-content {
	height: 105px;
	overflow-y: auto;
}
</style>
