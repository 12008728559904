<template>
	<v-layout class="main-layout" wrap :style="`background-color:${$store.state.MainColor1};`" justify-center>
		<routerIcons />
		<v-flex xs12>
			<v-sheet height="64" width="100%" max-width="1200" style="margin:0 auto;" color="MainColor1">
				<v-toolbar flat dark color="MainColor1" class="calender-toolbar">
					<v-btn outlined class="mr-4" @click="setToday" v-show="!$vuetify.breakpoint.xs">
						Today
					</v-btn>
					<v-btn fab text small @click="prev">
						<v-icon small>
							mdi-chevron-left
						</v-icon>
					</v-btn>
					<v-btn fab text small @click="next">
						<v-icon small>
							mdi-chevron-right
						</v-icon>
					</v-btn>
					<v-toolbar-title v-if="$refs.calendar">
						{{ $refs.calendar.title }}
					</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-menu bottom right>
						<template v-slot:activator="{ on, attrs }">
							<v-btn outlined v-bind="attrs" v-on="on">
								<span>{{ typeToLabel[type] }}</span>
								<v-icon right>
									mdi-menu-down
								</v-icon>
							</v-btn>
						</template>
						<v-list>
							<v-list-item @click="type = 'day'">
								<v-list-item-title>Day</v-list-item-title>
							</v-list-item>
							<v-list-item @click="type = 'week'">
								<v-list-item-title>Week</v-list-item-title>
							</v-list-item>
							<v-list-item @click="type = 'month'">
								<v-list-item-title>Month</v-list-item-title>
							</v-list-item>
							<v-list-item @click="type = '4day'">
								<v-list-item-title>4 days</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</v-toolbar>
			</v-sheet>
			<v-sheet height="600" width="100%" max-width="1200" style="margin:0 auto;" color="MainColor1">
				<v-calendar
					dark
					ref="calendar"
					v-model="focus"
					color="PointColor1"
					:events="events"
					:event-color="getEventColor"
					:type="type"
					@click:event="showEvent"
					@click:more="viewDay"
					@click:date="viewDay"
					@change="updateRange"
				></v-calendar>
				<v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
					<v-card color="grey lighten-4" min-width="350px" flat>
						<v-toolbar :color="selectedEvent.color" dark>
							<v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
						</v-toolbar>
						<v-card-text>
							<span v-html="selectedEvent.content"></span>
						</v-card-text>
					</v-card>
				</v-menu>
			</v-sheet>
		</v-flex>
		<v-flex xs12>
			<div style="max-width:1200px; margin:16px auto;">
				<v-layout>
					<workCard :data="workCard" />
				</v-layout>
				<v-layout class="mb-2">
					<h2 class="white--text">
						Vacation List
					</h2>
					<v-spacer></v-spacer>
					<v-btn @click="createVacationDialog" color="success">
						create
					</v-btn>
				</v-layout>
				<v-layout align-center class="mb-2">
					<DatepickerRange :picker="vacationStartpicker" @input="vacationDate"></DatepickerRange>
					<span class="mx-3 white--text"> ~ </span>
					<DatepickerRange :picker="vacationEndpicker" :allowed_dates="vacationCouselor" @input="vacationDate"></DatepickerRange>
					<v-spacer></v-spacer>
					<textField v-model="search" :textField="SearchTextField" />
				</v-layout>
				<datatable
					:search="search"
					:datatable="vacationDatatable"
					@vacationEdit="vacationEdit"
					@vacationReject="vacationReject"
					@vacationCancel="vacationCancel"
				>
				</datatable>
			</div>
		</v-flex>
		<dialog1 :setDialog="setDialog1" @cancel="setDialog1.dialog = false" @ok="updateVacation" />
		<createVacation :data="vacation" @create="createVacation" />
		<ruleDialog :data="reject" v-show="false" />
	</v-layout>
</template>
<script>
import { routerIcons } from './index'
import { dialog1, datatable, DatepickerRange, workCard, createVacation, textField, ruleDialog } from '@/components'
export default {
	components: { routerIcons, dialog1, datatable, DatepickerRange, workCard, createVacation, textField, ruleDialog },
	data() {
		return {
			reject: {
				dialog: false,
				title: 'Reject Vacation',
				content: '',
				btnText: 'ok',
			},
			search: '',
			SearchTextField: {
				dark: true,
				width: '200',
				placeholder: 'Search',
				['prepend-inner-icon']: 'mdi-magnify',
				solo: true,
				type: 'text',
				maxlength: 255,
			},
			vacation: {
				id: null,
				dialog: false,
				edit: false,
				content: '',
				type: 0,
				halfType: 0,
				picker: {
					date: [],
					range: true,
					rounded: true,
					errorMessage: '',
					menu: false,
					placeholder: 'Vacation Date',
					height: 56,
				},
				halfPicker: {
					date: null,
					range: false,
					rounded: true,
					errorMessage: '',
					menu: false,
					placeholder: 'Half Day Date',
					height: 56,
				},
			},
			workCard: {
				monthWorkTime: 168,
				holiayDayTime: 0,
				holiayDay: 0,
				Name: '',
				startdate: '',
				vacationsDays: 0,
				thisYearTotalWorkDays: 0,
				thisYearTotalWorkTime: 0,
				totalVacationDays: 0,
				Attendancelist: {
					dialog: false,
					btnText: 'work List',
					class: 'mr-2',
					interID: '',
				},
			},
			vacationStartpicker: {
				date: null,
				errorMessage: '',
				menu: false,
				placeholder: 'start',
				height: 48,
				width: 200,
			},
			vacationEndpicker: {
				date: null,
				errorMessage: '',
				menu: false,
				placeholder: 'end',
				height: 48,
				width: 200,
			},
			setDialog1: {
				dialog: false,
				title: 'Cancel Vacation',
				content: '',
				btnText: 'cancel',
			},
			vacationDatatable: {
				headers: [
					{ text: 'No', value: 'No', sortable: false },
					{ text: 'Name', value: 'users_permissions_user.Name' },
					{ text: 'Content', value: 'content' },
					{ text: 'Vacation Date', value: 'vacationDate' },
					{ text: 'Status', value: 'vacationStatus' },
					{ text: 'Action', value: 'vacationAction', sortable: false },
				],
				items: [],
				hidedefaultfooter: false,
				hidedefaultheader: false,
			},
			focus: '',
			type: 'month',
			typeToLabel: {
				month: 'Month',
				week: 'Week',
				day: 'Day',
				'4day': '4 Days',
			},
			selectedEvent: {},
			selectedElement: null,
			selectedOpen: false,
			events: [],
			colors: ['PointColor1', 'PointColor2'],
			names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
			vacationList: [],
			vacationId: null,
			userId: null,
			vacationCancelData: null,
		}
	},

	methods: {
		updateUser(type) {
			this.$store.dispatch('me').then(res => {
				let data
				if (type === 'create' || type === 'edit') {
					console.log(this.vacation.type)
					if (this.vacation.type === 0) {
						const t1 = this.$moment(this.vacation.picker.date[0])
						const t2 = this.$moment(this.vacation.picker.date[1])
						data = {
							id: this.$store.state.myData.id,
							vacationsDays: res.vacationsDays - t2.diff(t1, 'days') - 1,
						}
					} else {
						data = {
							id: this.$store.state.myData.id,
							vacationsDays: res.vacationsDays - (this.vacation.halfType === 0 ? 0.5 : 1),
						}
						console.log(this.vacation.halfType)
					}
				} else {
					const t1 = this.$moment(this.vacationCancelData.vacation)
					const t2 = this.$moment(this.vacationCancelData.vacationEnd)
					t2.diff(t1, 'days')
					if (this.vacationCancelData.halfDay === null) {
						data = {
							id: this.userId,
							vacationsDays: res.vacationsDays + t2.diff(t1, 'days') + 1,
						}
					} else {
						data = {
							id: this.userId,
							vacationsDays: res.vacationsDays + this.vacationCancelData.halfDay,
						}
					}
				}
				this.$store
					.dispatch('updateUser', data)
					.then(() => {
						this.$store.dispatch('me').then(() => {
							this.Attendancelist()
							this.vacation.dialog = false
							this.vacation.content = ''
							this.vacation.type = 0
							this.vacation.halfType = 0
							this.vacation.picker.date = []
							this.vacation.halfPicker.date = null
						})
					})
					.catch(err => {
						console.log({ err })
					})
			})
		},
		vacationEdit(val, item) {
			val
			if (item.status === 1) {
				this.userId = item.users_permissions_user.id
				this.vacation.edit = true
				this.vacation.dialog = true
				this.vacation.content = item.content
				this.vacation.type = item.halfDay ? 1 : 0
				this.vacation.halfType = item.halfDay ? item.halfDay : 0
				this.vacation.id = item.id
				if (item.halfDay >= 0 && item.halfDay !== null) {
					this.vacation.type = 1
					this.vacation.halfDay = item.halfDay
					this.vacation.picker.date = []
					this.vacation.halfPicker.date = item.vacation.substring(0, 10)
				} else {
					this.vacation.type = 0
					this.vacation.picker.date = [item.vacation.substring(0, 10), item.vacationEnd.substring(0, 10)]
					this.vacation.halfPicker.date = null
				}
			}
		},
		vacationReject(val, item) {
			val
			this.reject.dialog = true
			this.reject.content = item.reject
		},
		vacationCancel(val, item) {
			val
			this.vacationCancelData = item
			this.vacationId = item.id
			this.userId = item.users_permissions_user.id
			this.setDialog1.dialog = true
			this.setDialog1.content =
				'Are you really sure to ' +
				item.users_permissions_user.Name +
				'(' +
				this.$moment(item.vacation).format('YYYY-MM-DD') +
				' ~ ' +
				this.$moment(item.vacationEnd).format('YYYY-MM-DD') +
				')' +
				' vacation Cancel?'
		},
		createVacationDialog() {
			this.vacation.edit = false
			this.vacation.dialog = true
			this.vacation.type = 0
			// this.vacation.halfType = 0
		},
		vacations(type) {
			const data = {
				vacation_gte: this.vacationStartpicker.date + 'T00:00:00.000Z',
				vacation_lte: this.vacationEndpicker.date + 'T00:00:00.000Z',
			}
			this.$store
				.dispatch('vacations', data)
				.then(res => {
					if (!type) {
						this.Attendancelist()
					}
					this.vacationDatatable.items = res.vacations
					this.updateRange()
				})
				.catch(err => {
					console.log({ err })
				})
		},
		createVacation() {
			if (this.vacation.type === 0) {
				if (this.vacation.picker.date === null) {
					return alert('required date')
				}
			} else if (this.vacation.type === 1) {
				if (this.vacation.halfPicker.date === null) {
					return alert('required date')
				}
			}
			if (this.vacation.content === '') {
				return alert('required content')
			}
			if (this.vacation.type === 0) {
				const t1 = this.$moment(this.vacation.picker.date[0])
				const t2 = this.$moment(this.vacation.picker.date[1])
				if (this.$store.state.myData.vacationsDays - t2.diff(t1, 'days') - 1 < 0) {
					return alert('vacationsDays is 0')
				}
			} else {
				if (this.$store.state.myData.vacationsDays - (this.vacation.halfType === 0 ? 0.5 : 1) < 0) {
					return alert('vacationsDays is 0')
				}
			}
			let data
			if (this.vacation.type === 0) {
				data = {
					user: this.$store.state.myData.id,
					vacation: this.vacation.picker.date[0] + 'T00:00:00.000Z',
					content: this.vacation.content,
					vacationEnd: this.vacation.picker.date[1] + 'T00:00:00.000Z',
					halfDay: null,
				}
			} else if (this.vacation.type === 1) {
				data = {
					user: this.$store.state.myData.id,
					vacation: this.vacation.halfPicker.date + 'T00:00:00.000Z',
					content: this.vacation.content,
					vacationEnd: this.vacation.halfPicker.date + 'T00:00:00.000Z',
					halfDay: this.vacation.halfType,
				}
			} else {
				return alert('required type')
			}
			if (this.vacation.edit) {
				data.id = this.vacation.id
				this.$store
					.dispatch('updateVacation', data)
					.then(() => {
						this.updateUser('edit')
						this.vacations('edit')
					})
					.catch(err => {
						console.log({ err })
					})
			} else {
				this.$store
					.dispatch('createVacation', data)
					.then(() => {
						this.updateUser('create')
						this.vacations('create')
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		updateVacation() {
			const data = {
				id: this.vacationId,
				status: 99,
			}
			this.$store
				.dispatch('updateVacation', data)
				.then(() => {
					this.setDialog1.dialog = false
					this.vacationId = null
					this.vacations()
					this.updateUser()
				})
				.catch(err => {
					console.log({ err })
				})
		},
		vacationCouselor(val) {
			let start = this.$moment(this.vacationStartpicker.date).format('YYMMDD')

			let startDate = new Date(this.vacationStartpicker.date)
			let end = new Date(startDate.setDate(startDate.getDate() + 181))
			let endDate = this.$moment(end).format('YYMMDD')

			val = this.$moment(val).format('YYMMDD') < endDate && this.$moment(val).format('YYMMDD') > start
			return val
		},
		vacationDate() {
			this.vacationStartpicker.menu = false
			this.vacationEndpicker.menu = false
			this.vacations()
		},
		viewDay({ date }) {
			this.focus = date
			this.type = 'day'
		},
		getEventColor(event) {
			return event.color
		},
		setToday() {
			this.focus = ''
		},
		prev() {
			this.$refs.calendar.prev()
		},
		next() {
			this.$refs.calendar.next()
		},
		showEvent({ nativeEvent, event }) {
			const open = () => {
				this.selectedEvent = event
				this.selectedElement = nativeEvent.target
				requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)))
			}

			if (this.selectedOpen) {
				this.selectedOpen = false
				requestAnimationFrame(() => requestAnimationFrame(() => open()))
			} else {
				open()
			}

			nativeEvent.stopPropagation()
		},
		updateRange() {
			const events = []
			this.vacationDatatable.items.forEach(element => {
				if (element.status === 3) {
					events.push({
						name: `${
							element.halfDay === null
								? element.users_permissions_user.Name
								: element.users_permissions_user.Name + ' (' + (element.halfDay === 0 ? '0.5' : '1') + ')'
						}`,
						start: this.$moment(element.vacation).format('YYYY-MM-DD'),
						end: this.$moment(element.vacationEnd).format('YYYY-MM-DD'),
						color: this.colors[this.rnd(0, this.colors.length - 1)],
						timed: false,
						content: element.content,
					})
				}
			})

			this.events = events
		},
		rnd(a, b) {
			return Math.floor((b - a + 1) * Math.random()) + a
		},
		Attendancelist() {
			if (this.$store.state.myData.interID) {
				let data
				const notYears = this.$moment(this.$moment().format('YYYY-MM-DD')).isBetween(
					this.$moment(this.$store.state.myData.startdate).format('YYYY-MM-DD'),
					this.$moment(this.$store.state.myData.startdate)
						.add(1, 'years')
						.format('YYYY-MM-DD'),
					undefined,
					'[]',
				)
				if (notYears) {
					data = {
						workday_gte: this.$store.state.myData.startdate,
						interID: this.$store.state.myData.interID,
					}
				} else {
					data = {
						workday_gte: this.$moment(new Date()).format('YYYY-') + '01-01',
						interID: this.$store.state.myData.interID,
					}
				}
				this.$store
					.dispatch('Attendancelist', data)
					.then(res => {
						let holiayDay = 0
						let holiayDayTime = 0
						let totalMinute = 0
						let totalDay = 0
						let monthWorks = [
							{ [1]: 0 },
							{ [2]: 0 },
							{ [3]: 0 },
							{ [4]: 0 },
							{ [5]: 0 },
							{ [6]: 0 },
							{ [7]: 0 },
							{ [8]: 0 },
							{ [9]: 0 },
							{ [10]: 0 },
							{ [11]: 0 },
							{ [12]: 0 },
						]
						res.attendances.forEach(attendance => {
							if (this.$moment(attendance.work).day() !== 0 && this.$moment(attendance.work).day() !== 6) {
								let t2
								if (attendance.Leave) {
									t2 = this.$moment(attendance.Leave)
								} else {
									t2 = this.$moment(attendance.work).add(6, 'hours')
								}
								const t1 = this.$moment(attendance.work)
								totalMinute += t2.diff(t1, 'minutes')
								totalDay += 1
								monthWorks[Number(this.$moment(attendance.work).format('MM')) - 1][Number(this.$moment(attendance.work).format('MM'))] +=
									t2.diff(t1, 'minutes') / 60
							} else {
								holiayDay += 1
								let t3 = 0
								if (attendance.Leave) {
									t3 = this.$moment(attendance.Leave)
								} else {
									t3 = this.$moment(attendance.work).add(6, 'hours')
								}
								const t4 = this.$moment(attendance.work)
								holiayDayTime += t3.diff(t4, 'minutes')
							}
						})
						this.workCard.Name = this.$store.state.myData.Name
						this.workCard.startdate = this.$store.state.myData.startdate
						this.workCard.vacationsDays = this.$store.state.myData.vacationsDays
						this.workCard.thisYearTotalWorkDays = totalDay
						this.workCard.thisYearTotalWorkTime = Math.round(totalMinute / 60)
						this.workCard.holiayDay = holiayDay
						this.workCard.holiayDayTime = Math.round(holiayDayTime / 60)
						this.workCard.Attendancelist.interID = this.$store.state.myData.interID
						if (notYears) {
							monthWorks.forEach((month, index) => {
								if (month[index + 1] > this.workCard.monthWorkTime * 0.8) {
									this.workCard.totalVacationDays += 1
								}
							})
						} else {
							const y1 = this.$moment(this.$store.state.myData.startdate)
							const y2 = this.$moment()
							const years = y2.diff(y1, 'months', true)
							this.workCard.totalVacationDays = 15 + Math.floor((Math.floor(years / 12) - 1) / 2)
						}
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
	},
	created() {
		this.vacationStartpicker.date = this.$moment(new Date())
			.subtract(90, 'days')
			.format('YYYY-MM-DD')
		this.vacationEndpicker.date = this.$moment(new Date())
			.add(90, 'days')
			.format('YYYY-MM-DD')
		this.vacations()
	},
	mounted() {
		this.$refs.calendar.checkChange()
	},
}
</script>
<style lang="scss">
.calender-toolbar {
	.v-toolbar__content {
		padding: 4px 0px !important;
	}
}
</style>
