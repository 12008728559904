<template>
	<v-layout justify-center class="main-layout" wrap :style="`background-color:${$store.state.MainColor1};`">
		<routerIcons />
		<v-flex xs12>
			<v-card class="mt-4">
				<v-tabs dark background-color="PointColor2" show-arrows v-model="tab">
					<v-tabs-slider color=""></v-tabs-slider>
					<v-tab v-for="(i, index) in userList" :key="index" :href="'#' + i.username" tile> {{ i.Name }} </v-tab>
				</v-tabs>
			</v-card>
		</v-flex>
		<v-flex lg4 xs12>
			<div>
				<v-img
					v-if="userData.face"
					width="100%"
					height="300"
					contain
					lazy-src="/image/img-profile-default-s.png"
					:src="server + userData.face.url"
				></v-img>
				<v-img v-else width="100%" height="300" contain src="/image/Operation/img-profile-default-s.png"></v-img>
			</div>
			<div>
				<v-card class="mx-auto" tile>
					<v-list height="440">
						<v-subheader
							>{{ userData.position }} - {{ userData.Name }}의 코드리뷰 <v-spacer></v-spacer>
							<v-btn v-if="userData.username === $store.state.myData.username" color="primary" @click="createReview"
								>등록</v-btn
							></v-subheader
						>
						<v-list-item-group v-model="selectedItem" color="primary" class="overflow">
							<v-layout v-for="(item, i) in codeShowsData" :key="i" align-center>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title>{{ item.title + ' (' + item.date + ')' }}</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-btn v-if="userData.username === $store.state.myData.username" class="mr-2" color="warning" @click="editReview(item)"
									>수정</v-btn
								>
								<v-btn v-if="userData.username === $store.state.myData.username" color="error" @click="deleteReview(item)">삭제</v-btn>
							</v-layout>
						</v-list-item-group>
					</v-list>
				</v-card>
			</div>
		</v-flex>
		<v-flex lg8 xs12>
			<vue-pdf-app :pdf="pdf"></vue-pdf-app>
		</v-flex>
		<!-- 직원관리 등록 부분 -->
		<v-dialog v-model="dialog" width="1300" persistent>
			<div class="dialog-card-1 ppb-50" style="background-color:white;">
				<v-layout wrap>
					<div class="dialog-text-1 mmt-45 mml-37 mmb-24">코드리뷰 {{ create.create ? '등록' : '수정' }}</div>
					<v-spacer></v-spacer>
					<div class="dialog-icon-1 mmt-45 mmr-37" @click="dialog = false"></div>
				</v-layout>
				<v-divider></v-divider>
				<!-- 기본정보 데이터 보여지는 부분 -->
				<div class="mml-37 mmt-33 dialog-card-3">
					<v-layout class="border-bottom-1">
						<div class="dialog-label-1 border-right-1 ppt-15 ppl-30 ppb-15">제목</div>
						<!-- <div class="dialog-filed-1  ppt-15 ppl-30 ppb-15"></div> -->
						<textField :width="906" class="mml-15 mt-2 mmr-15" type="text" v-model="create.title" />
					</v-layout>
					<v-layout wrap class="border-bottom-1">
						<v-flex xs12>
							<v-layout class="border-bottom-1">
								<div class="dialog-label-1 border-right-1  ppt-15 ppl-30 ppb-15">내용</div>
								<textField :width="906" class="mml-15 mt-2 mmr-15" v-model="create.content" />
							</v-layout>
							<v-layout class="border-bottom-1" align-center>
								<div class="dialog-label-1 border-right-1  ppt-15 ppl-30 ppb-15" style="height:80px; display: flex; align-items: center;">
									등록날짜
								</div>
								<v-menu
									ref="menu1"
									v-model="menu1"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
									max-width="290px"
									min-width="290px"
									style="border-radius:0px;"
								>
									<template v-slot:activator="{ on }">
										<div class="cal-container">
											<textField v-model="dateFormatted1" :width="190" class="mml-15 mt-6" readonly v-on="on" />
										</div>
									</template>
									<v-date-picker
										:show-current="false"
										v-model="date1"
										no-title
										@input="menu1 = false"
										color="#D5D8E0"
										locale="ko"
									></v-date-picker>
								</v-menu>
							</v-layout>
							<v-layout>
								<div class="dialog-label-1 border-right-1  ppt-15 ppl-30 ppb-15">PDF 파일</div>
								<v-file-input
									v-model="file"
									label="PDF"
									outlined
									dense
									accept="application/pdf"
									class="mt-2 mml-15 mmr-15"
									@change="filesupload"
									hideDetails
									truncate-length="50"
								></v-file-input>
							</v-layout>
						</v-flex>
					</v-layout>
				</div>
				<div class="text-right mmr-37 mmt-40 btn-lay-1">
					<v-btn class="M-btn-5" @click="dialog = false">취소</v-btn>
					<v-btn class="M-btn-4" @click="createCodeShow()">저장</v-btn>
				</div>
			</div>
		</v-dialog>
		<dialog1 :setDialog="setDialog1" @cancel="Cancel" @ok="Ok" />
	</v-layout>
</template>
<script>
import VuePdfApp from 'vue-pdf-app'
import 'vue-pdf-app/dist/icons/main.css'
import { routerIcons } from './index'
import { textField, dialog1 } from '@/components'
export default {
	components: { dialog1, VuePdfApp, textField, routerIcons },
	data() {
		return {
			server: process.env.VUE_APP_BACKEND_URL,
			pdf: null,
			file: null,
			setDialog1: {
				dialog: false,
				title: 'Delete Code Review',
				content: 'Are you really sure to delete this Code Review?',
				btnText: 'delete',
			},
			userData: { face: { url: '' } },
			userList: [],
			codeShowsData: [],
			codeShowData: {},
			filedata: null,
			menu1: false,
			date1: '',
			dateFormatted1: '',
			create: {
				create: true,
				title: '',
				date: '',
				content: '',
			},
			dialog: false,
			selectedItem: 0,
			items: [
				{ text: 'Real-Time', icon: 'mdi-clock' },
				{ text: 'Audience', icon: 'mdi-clock' },
				{ text: 'Conversions', icon: 'mdi-clock' },
				{ text: 'Conversions', icon: 'mdi-clock' },
				{ text: 'Conversions', icon: 'mdi-clock' },
				{ text: 'Conversions', icon: 'mdi-clock' },
				{ text: 'Conversions', icon: 'mdi-clock' },
				{ text: 'Conversions', icon: 'mdi-clock' },
				{ text: 'Conversions', icon: 'mdi-clock' },
			],
			tab: null,
			date: 0,
			delId: null,
		}
	},
	watch: {
		selectedItem(val) {
			if (this.codeShowsData[val]) {
				this.pdf = this.server + this.codeShowsData[val].pdFile.url
			} else {
				this.pdf = ''
			}
		},
		file(val) {
			console.log(val)
		},
		date1() {
			this.dateFormatted1 = this.formatDate(this.date1)
		},
		tab(val) {
			const index = this.userList.map(x => x.username).indexOf(val)
			this.userData = this.userList[index]
			this.codeShows()
		},
	},
	methods: {
		createReview() {
			this.dialog = true
			this.create = {
				create: true,
				title: '',
				date: '',
				content: '',
			}
			this.date1 = ''
			this.file = null
		},
		Ok() {
			const data = {
				id: this.delId,
			}
			this.$store
				.dispatch('deleteCodeShow', data)
				.then(() => {
					this.setDialog1.dialog = false
					this.codeShows()
				})
				.catch(err => {
					console.log({ err })
				})
		},
		Cancel() {
			this.setDialog1.dialog = false
		},
		deleteReview(item) {
			this.delId = item.id
			this.setDialog1.dialog = true
			this.setDialog1.dialogTitle = '코드리뷰 삭제'
			this.setDialog1.dialogContent = `${item.title + ' (' + item.date + ') '} 코드리뷰를 삭제 하시겠습니까?`
			console.log(this.setDialog1)
		},
		editReview(item) {
			this.editID = item.id
			this.dialog = true
			this.create = {
				create: false,
				title: item.title,
				content: item.content,
			}
			this.date1 = item.date
			this.file = {
				name: item.pdFile.name,
			}
		},

		allUsers() {
			this.$store
				.dispatch('allUsers')
				.then(res => {
					const setArray = [
						'이규화',
						'박경실',
						'이현우',
						'전현균',
						'지현재',
						'이원회',
						'이윤세',
						'최희철',
						'조재호',
						'성시헌',
						'임호진',
						'노의진',
					]
					res.users.forEach(element => {
						if (setArray.indexOf(element.Name) > -1) {
							this.userList.push(element)
						}
					})
					const index = this.userList.map(x => x.username).indexOf('splash1')
					this.userData = this.userList[index]
					this.codeShows()
				})
				.catch(err => {
					console.log({ err })
				})
		},
		filesupload(event) {
			this.filedata = event
		},
		picupload() {
			document.getElementById('pic-upload').click()
		},
		formatDate(date) {
			if (!date) return null
			const [year, month, day] = date.split('-')
			return `${year}-${month}-${day}`
		},
		codeShows() {
			const data = {
				user: this.userData.id,
			}
			this.$store
				.dispatch('codeShows', data)
				.then(res => {
					this.codeShowsData = res.codeShows
				})
				.catch(err => {
					console.log({ err })
				})
		},
		codeShow(id) {
			const data = {
				id: id,
			}
			this.$store
				.dispatch('codeShow', data)
				.then(res => {
					this.codeShow = res
					console.log(res)
				})
				.catch(err => {
					console.log({ err })
				})
		},
		createCodeShow() {
			if (!this.create.create) {
				this.updateCodeShow()
				return
			}
			if (this.create.title === '') {
				alert('코드리뷰 제목을 입력해 주세요.')
				return
			}
			if (this.create.content === '') {
				alert('코드리뷰 내용을 입력해 주세요.')
				return
			}
			if (this.date1 === '') {
				alert('코드리뷰 날짜를 입력해 주세요.')
				return
			}
			if (this.file === null) {
				alert('PDF 파일을 등록해 주세요!')
				return
			}

			const data = {
				file: this.filedata,
			}
			this.$store.dispatch('fileUpload', data).then(res => {
				console.log(res)
				const data = {
					title: this.create.title,
					date: this.date1,
					content: this.create.content,
					user: Number(this.$store.state.myData.id),
					pdFile: Number(res[0].id),
				}
				this.$store
					.dispatch('createCodeShow', data)
					.then(() => {
						this.codeShows()
						this.dialog = false
						this.create.title = ''
						this.create.content = ''
						this.filedata = null
						this.date1 = ''
					})
					.catch(err => {
						console.log({ err })
					})
			})
		},
		updateCodeShow() {
			if (this.create.title === '') {
				alert('코드리뷰 제목을 입력해 주세요.')
				return
			}
			if (this.create.content === '') {
				alert('코드리뷰 내용을 입력해 주세요.')
				return
			}
			if (this.date1 === '') {
				alert('코드리뷰 날짜를 입력해 주세요.')
				return
			}
			if (this.file === null) {
				alert('PDF 파일을 등록해 주세요!')
				return
			}
			if (this.filedata === null) {
				const data = {
					id: this.editID,
					title: this.create.title,
					date: this.date1,
					content: this.create.content,
				}
				this.$store
					.dispatch('updateCodeShow', data)
					.then(() => {
						this.dialog = false
						this.create.title = ''
						this.create.content = ''
						this.filedata = null
						this.date1 = ''
						this.codeShows()
					})
					.catch(err => {
						console.log({ err })
					})
			} else {
				const data = {
					file: this.filedata,
				}
				this.$store.dispatch('fileUpload', data).then(res => {
					const data = {
						id: this.editID,
						title: this.create.title,
						date: this.date1,
						content: this.create.content,
						pdFile: Number(res.data.upload.id),
					}
					this.$store
						.dispatch('updateCodeShow', data)
						.then(() => {
							this.dialog = false
							this.create.title = ''
							this.create.content = ''
							this.filedata = null
							this.date1 = ''
							this.codeShows()
						})
						.catch(err => {
							console.log({ err })
						})
				})
			}
		},
	},
	created() {
		this.allUsers()
	},
}
</script>
<style lang="scss">
.pdf-app .toolbar {
	z-index: 200 !important;
}
.overflow {
	overflow: auto;
	height: 392px;
}
</style>
