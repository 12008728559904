<template>
	<v-layout :style="`background-color:${$store.state.MainColor1};`" class="main-layout" justify-center wrap>
		<routerIcons />
		<v-flex lg12 sm12 xs12 class="mb-4">
			<div :class="$vuetify.breakpoint.smAndDown ? '' : 'mx-2'">
				<h2 class="white--text">
					금일 근무현황
				</h2>
				<v-layout align-center class="my-2">
					<btn :btn="workBtn" btn_txt="refresh" @click="Attendancelist()" />
					<v-spacer></v-spacer>
					<textField v-model="DepartmentSearch" :textField="SearchTextField" />
				</v-layout>
				<datatable :datatable="datatable" :search="DepartmentSearch" />
			</div>
		</v-flex>

		<dialog1 :setDialog="setDialog1" @cancel="setDialog1.dialog = false" @ok="dialogEvents" />
	</v-layout>
</template>

<script>
import { routerIcons } from './index'
import { datatable, textField, btn, dialog1 } from '@/components'
export default {
	components: {
		datatable,
		textField,
		routerIcons,
		btn,
		dialog1,
	},
	computed: {
		checkProgress() {
			let val = 0
			val = (this.workCheckDatatable.items.filter(x => x.status === 3).length / this.workCheckDatatable.items.length) * 100
			return val
		},
	},
	data: () => ({
		todoListId: null,
		actionItem: {},
		setDialog1: {
			dialog: false,
			title: '',
			content: '',
			btnText: '',
			type: '',
		},
		workDatatable: {
			headers: [
				{ text: 'No', value: 'No', sortable: false },
				{ text: 'Title', value: 'title' },
				{ text: 'DeadLine', value: 'deadline' },
				{ text: 'User', value: 'user' },
				{ text: 'Status', value: 'status' },
				{ text: 'Action', value: 'workAction', sortable: false },
			],
			items: [],
			hidedefaultfooter: false,
			hidedefaultheader: false,
			singleExpand: true,
			expanded: [],
			['show-expand']: true,
			key: 'created_at',
			comment: '',
		},
		workCheckDatatable: {
			headers: [
				{ text: 'No', value: 'No', sortable: false },
				{ text: 'Title', value: 'title' },
				{ text: 'DeadLine', value: 'deadline' },
				{ text: 'User', value: 'user' },
				{ text: 'Status', value: 'status' },
				{ text: 'Action', value: 'workCheckAction', sortable: false },
			],
			items: [],
			hidedefaultfooter: false,
			hidedefaultheader: false,
			singleExpand: true,
			expanded: [],
			['show-expand']: true,
			key: 'created_at',
			comment: '',
		},
		endTimepicker: {
			date: null,
			errorMessage: '',
			menu: false,
			backgroundColor: 'PointColor2',
			height: 48,
			placeholder: 'deadline',
			width: 150,
		},
		workStartpicker: {
			date: null,
			errorMessage: '',
			menu: false,
			placeholder: 'start',
			height: 48,
		},
		workEndpicker: {
			date: null,
			errorMessage: '',
			menu: false,
			placeholder: 'end',
			height: 48,
		},
		workCheckStartpicker: {
			date: null,
			errorMessage: '',
			menu: false,
			placeholder: 'start',
			height: 48,
		},
		workCheckEndpicker: {
			date: null,
			errorMessage: '',
			menu: false,
			placeholder: 'end',
			height: 48,
		},
		workSaveBtn: {
			dark: true,
			color: 'PointColor1',
			height: 48,
			class: '',
		},
		workBtn: {
			dark: true,
			color: 'PointColor1',
			height: 48,
			class: 'mr-2',
		},
		work: '',
		workTextField: {
			['background-color']: 'PointColor2',
			dark: true,
			autofocus: false,
			width: '100%',
			['error-messages']: '',
			placeholder: 'Work Title',
			['prepend-inner-icon']: 'mdi-pencil',
			solo: true,
			type: 'text',
			maxlength: 255,
		},
		DepartmentSearch: '',
		workSearch: '',
		workCheckSearch: '',
		SearchTextField: {
			dark: true,
			width: '200',
			placeholder: 'Search',
			['prepend-inner-icon']: 'mdi-magnify',
			solo: true,
			type: 'text',
			maxlength: 255,
		},
		datatable: {
			headers: [
				{
					text: 'NO',
					align: 'center',
					sortable: false,
					value: 'No',
				},
				{ text: 'Name', value: 'Name' },
				{ text: 'Position', value: 'position' },
				{ text: 'PhoneNumber', value: 'phone' },
				{ text: 'get to work', value: 'work' },
				{ text: 'leave work', value: 'Leave' },
				{ text: 'etc', value: 'notes' },
			],
			items: [],
		},
		Projectsearch: '',
		ProjectDatatable: {
			headers: [
				{
					text: 'NO',
					align: 'center',
					sortable: false,
					value: 'No',
				},
				{ text: 'Period', value: 'period', sortable: false },
				{ text: 'Project Title', value: 'name' },
				{ text: 'Manager', value: 'pmName' },
				{ text: 'Work URL', value: 'workUrl', sortable: false },
			],
			items: [],
		},
		workItem: {},
		workItemComment: {},
		workList: [],
		interID: null,
		workSelect: '',
	}),
	methods: {
		workActionDelete(item) {
			this.$store.state.loading = true
			const data = { interID: this.interID }
			this.$store
				.dispatch('todolists', data)
				.then(res => {
					const lists = res.todolists[0].list
					lists.splice(res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at), 1)
					const data = {
						id: this.todoListId,
						list: lists,
					}
					this.$store
						.dispatch('updateTodolist', data)
						.then(() => {
							this.$store.state.loading = false
							this.todolists()
						})
						.catch(err => {
							this.$store.state.loading = false
							console.log({ err })
						})
				})
				.catch(err => {
					this.$store.state.loading = false
					console.log({ err })
				})
		},
		deadLineDates(val) {
			let start = this.$moment().format('YYMMDD')

			let startDate = new Date(this.workStartpicker.date)
			let end = new Date(startDate.setDate(startDate.getDate() + 60))
			let endDate = this.$moment(end).format('YYMMDD')

			val = this.$moment(val).format('YYMMDD') <= endDate && this.$moment(val).format('YYMMDD') >= start
			return val
		},
		workCheckActionDialog(val, item) {
			val
			this.workItem = item
			this.setDialog1.dialog = true
			this.setDialog1.title = 'Check the Work'
			this.setDialog1.type = 'Check-the-Work'
			this.setDialog1.content = 'Are you really sure to check this work? - "' + item.title + '"'
			this.setDialog1.btnText = 'check'
		},
		workAction(item) {
			this.$store.state.loading = true
			const data = { interID: this.interID }
			this.$store
				.dispatch('todolists', data)
				.then(res => {
					const lists = res.todolists[0].list
					if (lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].status === 1) {
						lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].status = 2
					} else if (lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].status === 2) {
						lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].status = 3
						lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].checker.push({
							name: this.$store.state.myData.Name,
							email: this.$store.state.myData.email,
						})
					} else if (lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].status === 3) {
						lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].status = 1
					}
					const data = {
						id: this.todoListId,
						list: lists,
					}
					this.$store
						.dispatch('updateTodolist', data)
						.then(() => {
							this.$store.state.loading = false
							this.todolists()
						})
						.catch(err => {
							this.$store.state.loading = false
							console.log({ err })
						})
				})
				.catch(err => {
					this.$store.state.loading = false
					console.log({ err })
				})
		},
		todolists() {
			if (!this.interID) {
				return alert('error - no inter ID')
			}
			this.$store.state.loading = true
			const data = { interID: this.interID }
			this.$store
				.dispatch('todolists', data)
				.then(res => {
					this.setDialog1.dialog = false
					this.$store.state.loading = false
					if (res.todolists.length > 0) {
						this.todoListId = res.todolists[0].id
						this.workList = JSON.parse(JSON.stringify(res.todolists[0].list))
						this.workDatatable.items = res.todolists[0].list
							.filter(
								x =>
									new Date(x.deadline) >= new Date(this.workStartpicker.date) && new Date(x.deadline) <= new Date(this.workEndpicker.date),
							)
							.reverse()
						this.workCheckDatatable.items = res.todolists[0].list
							.filter(
								x =>
									new Date(x.deadline) >= new Date(this.workStartpicker.date) &&
									new Date(x.deadline) <= new Date(this.workEndpicker.date) &&
									x.status >= 2 &&
									x.email !== this.$store.state.myData.email,
							)
							.reverse()
					} else {
						this.workDatatable.items = []
						this.workCheckDatatable.items = []
					}
				})
				.catch(err => {
					this.$store.state.loading = false
					console.log({ err })
				})
		},
		updateTodolist() {
			if (this.work === '') {
				return (this.workTextField['error-messages'] = 'check')
			}
			if (!this.endTimepicker.date) {
				return (this.endTimepicker.errorMessage = 'check')
			}
			this.$store.state.loading = true
			const data = { interID: this.interID }
			this.$store
				.dispatch('todolists', data)
				.then(res => {
					let lists
					if (res.todolists.length > 0) {
						lists = res.todolists[0].list
					} else {
						lists = []
					}
					lists.push({
						created_at: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
						title: this.work,
						deadline: this.endTimepicker.date,
						status: 1,
						user: this.$store.state.myData.Name,
						email: this.$store.state.myData.email,
						checker: [],
						comments: [],
					})
					const data = {
						id: this.todoListId,
						list: lists,
					}
					this.$store
						.dispatch('updateTodolist', data)
						.then(() => {
							this.$store.state.loading = false
							this.work = ''
							this.endTimepicker.date = null
							this.todolists()
						})
						.catch(err => {
							this.$store.state.loading = false
							console.log({ err })
						})
				})
				.catch(err => {
					this.$store.state.loading = false
					console.log({ err })
				})
		},
		workCouselor(val) {
			let start = this.$moment(this.workStartpicker.date).format('YYMMDD')

			let startDate = new Date(this.workStartpicker.date)
			let end = new Date(startDate.setDate(startDate.getDate() + 60))
			let endDate = this.$moment(end).format('YYMMDD')

			val = this.$moment(val).format('YYMMDD') < endDate && this.$moment(val).format('YYMMDD') > start
			return val
		},
		workActionDialog(val, item) {
			val
			this.actionItem = item
			this.setDialog1.dialog = true
			if (item.status === 1) {
				this.setDialog1.title = 'report to Leader'
				this.setDialog1.type = 'report'
				this.setDialog1.content = 'Are you really sure to report to ' + item.title + '?'
				this.setDialog1.btnText = 'report'
			} else if (item.status === 3) {
				this.setDialog1.title = 'get back to working'
				this.setDialog1.type = 'return'
				this.setDialog1.content = 'Are you really sure to get back ' + item.title + '?'
				this.setDialog1.btnText = 'get back'
			}
		},
		workActionDeleteDialog(val, item) {
			val
			this.deleteItem = item
			this.setDialog1.dialog = true
			this.setDialog1.title = 'Work List Delete'
			this.setDialog1.type = 'workActionDelete'
			this.setDialog1.content = 'Are you really sure to delete ' + item.title + '?'
			this.setDialog1.btnText = 'delete'
		},
		myWorkListSelect(val) {
			const workList = []
			this.$store.state.myData.teams.forEach(team => {
				if (team.calendars.length > 0) {
					team.calendars.forEach(calendar => {
						workList.push(calendar)
					})
				}
			})
			const index = workList.map(x => x.name).indexOf(val)
			this.interID = workList[index].interID
			this.todolists(workList[index].interID)
		},
		endDate() {
			this.endTimepicker.errorMessage = ''
			this.endTimepicker.menu = false
		},
		workDate() {
			this.workStartpicker.menu = false
			this.workEndpicker.menu = false
			this.workDatatable.items = this.workList.filter(
				x => new Date(x.deadline) >= new Date(this.workStartpicker.date) && new Date(x.deadline) <= new Date(this.workEndpicker.date),
			)
		},
		workCheckDate() {
			this.workCheckStartpicker.menu = false
			this.workCheckEndpicker.menu = false
			this.workCheckDatatable.items = this.workList.filter(
				x =>
					new Date(x.deadline) >= new Date(this.workCheckStartpicker.date) &&
					new Date(x.deadline) <= new Date(this.workCheckEndpicker.date) &&
					x.status >= 2 &&
					x.email !== this.$store.state.myData.email,
			)
		},
		dialogEvents() {
			if (this.setDialog1.type === 'workActionDelete') {
				this.workActionDelete(this.deleteItem)
			} else if (this.setDialog1.type === 'report' || this.setDialog1.type === 'return') {
				this.workAction(this.actionItem)
			} else if (this.setDialog1.type === 'no-show-comment') {
				this.deleteComment(this.workItem, this.workItemComment)
			} else if (this.setDialog1.type === 'Check-the-Work') {
				this.workAction(this.workItem)
			}
		},
		deleteCommentDialog(val, item, chat) {
			val
			this.workItem = item
			this.workItemComment = chat
			this.setDialog1.dialog = true
			this.setDialog1.title = 'No-show comment'
			this.setDialog1.type = 'no-show-comment'
			this.setDialog1.content = 'Are you really sure to no-show this comment? - "' + chat.comment + '"'
			this.setDialog1.btnText = 'no-show'
		},
		deleteComment(item, chat) {
			this.$store.state.loading = true
			const data = { interID: this.interID }
			this.$store
				.dispatch('todolists', data)
				.then(res => {
					const lists = res.todolists[0].list
					const listIndex = lists.map(x => x.created_at).indexOf(item.created_at)
					const commentIndex = lists[listIndex].comments.map(y => y.created_at).indexOf(chat.created_at)
					lists[listIndex].comments[commentIndex].show = !lists[listIndex].comments[commentIndex].show
					const data = {
						id: this.todoListId,
						list: lists,
					}
					this.$store
						.dispatch('updateTodolist', data)
						.then(() => {
							this.$store.state.loading = false
							this.todolists()
						})
						.catch(err => {
							this.$store.state.loading = false
							console.log({ err })
						})
				})
				.catch(err => {
					this.$store.state.loading = false
					console.log({ err })
				})
		},
		updateComment(val, item) {
			if (this.workDatatable.comment !== '') {
				val
				this.$store.state.loading = true
				const data = { interID: this.interID }
				this.$store
					.dispatch('todolists', data)
					.then(res => {
						const lists = res.todolists[0].list
						const listIndex = lists.map(x => x.created_at).indexOf(item.created_at)
						lists[listIndex].comments.push({
							created_at: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
							comment: this.workDatatable.comment,
							user: this.$store.state.myData.Name,
							email: this.$store.state.myData.email,
							show: true,
							face: this.$store.state.myData.face,
						})
						const data = {
							id: this.todoListId,
							list: lists,
						}
						this.$store
							.dispatch('updateTodolist', data)
							.then(() => {
								this.$store.state.loading = false
								this.workDatatable.comment = ''
								this.todolists()
							})
							.catch(err => {
								this.$store.state.loading = false
								console.log({ err })
							})
					})
					.catch(err => {
						this.$store.state.loading = false
						console.log({ err })
					})
			} else if (this.workCheckDatatable.comment !== '') {
				val
				this.$store.state.loading = true
				const data = { interID: this.interID }
				this.$store
					.dispatch('todolists', data)
					.then(res => {
						const lists = res.todolists[0].list
						const listIndex = lists.map(x => x.created_at).indexOf(item.created_at)
						lists[listIndex].comments.push({
							created_at: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
							comment: this.workCheckDatatable.comment,
							user: this.$store.state.myData.Name,
							email: this.$store.state.myData.email,
							show: true,
						})
						const data = {
							id: this.todoListId,
							list: lists,
						}
						this.$store
							.dispatch('updateTodolist', data)
							.then(() => {
								this.$store.state.loading = false
								this.workCheckDatatable.comment = ''
								this.todolists()
							})
							.catch(err => {
								this.$store.state.loading = false
								console.log({ err })
							})
					})
					.catch(err => {
						this.$store.state.loading = false
						console.log({ err })
					})
			}
		},
		Attendancelist() {
			const data = {
				workday: this.$moment(new Date()).format('YYYY-MM-DD'),
			}
			this.$store
				.dispatch('Attendancelist', data)
				.then(res => {
					this.datatable.items = res.attendances
				})
				.catch(err => {
					console.log({ err })
				})
		},
		calendars() {
			const data = {
				start: this.$moment(new Date()).format('YYYY-') + '01-01',
			}
			this.$store
				.dispatch('calendars', data)
				.then(res => {
					this.ProjectDatatable.items = res.calendars
				})
				.catch(err => {
					console.log({ err })
				})
		},
	},
	created() {
		this.workStartpicker.date = this.$moment(new Date())
			.subtract(14, 'days')
			.format('YYYY-MM-DD')
		this.workEndpicker.date = this.$moment(new Date())
			.add(14, 'days')
			.format('YYYY-MM-DD')
		this.workCheckStartpicker.date = this.$moment(new Date())
			.subtract(14, 'days')
			.format('YYYY-MM-DD')
		this.workCheckEndpicker.date = this.$moment(new Date())
			.add(14, 'days')
			.format('YYYY-MM-DD')
		this.Attendancelist()
	},
}
</script>
